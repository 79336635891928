<template>
    <div>
        <div id="auth-window">
            <!-- <img id="profile-picture" :src="titlePicture" alt=""> -->
            <h3 id="user-name-preview">{{ title }}</h3>

            <div class="user-list">
                <div v-for="user of users" :key="user.id">
                    <a class="button" @click="select(user.auth_key)">Next</a>
                    <img :src="user.profile_picture">
                    <h4 :title="user.namespace ? `${user.name} (${user.namespace.display_name})` :  user.name">{{ user.namespace ? `${user.name} (${user.namespace.display_name})` :  user.name }}</h4>
                </div>
                <br><br>
                <div>
                    <router-link
                        :to="'/login?redirect='+encodeURIComponent('/switch?redirect='+encodeURIComponent(redirectUrl)+(this.temporarily ? '&temp=true' : ''))"
                        class="button">Login
                    </router-link>
                    <img src="/nopb.png">
                    <h4>Use other Account</h4>
                </div>
            </div>

            <p class="error-message">{{ errorMessage }}</p>

        </div>
        <div id="bottom">
            <a href="https://interaapps.de/imprint">Imprint</a>
            <a href="https://interaapps.de/privacy">Privacy</a>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        title: "Choose an Account",
        titlePicture: "https://cdn.interaapps.de/ia/icons/icon.svg",
        errorMessage: "",
        redirectUrl: "/",
        users: [],
        temporarily: false
    }),
    created() {

        if (this.$route.query.redirect) {
            if (!this.$route.query.redirect.includes("://"))
                this.redirectUrl = this.$route.query.redirect
        }

        if (this.$route.query.temp) {
            this.temporarily = true
        }

        this.load()
    },
    methods: {
        select(authKey) {
            if (this.temporarily) {
                this.apiClient.options.header["x-auth-key"] = authKey
                this.apiClient.loadUsersAndInsert()
                this.$router.push(this.redirectUrl);
            } else {
                this.apiClient.setApiKey(authKey)
                    .then(() => {
                        this.$router.push(this.redirectUrl);
                    })
            }
        },
        load() {
            let accounts = {}
            try {
                accounts = JSON.parse(localStorage["authkeys"])
            } catch (e) {
                localStorage["authkeys"] = "{}"
            }

            for (const id in accounts) {
                if (accounts[id]) {
                    const authKey = accounts[id]
                    console.log(authKey);
                    this.apiClient.get("/api/v2/user", {}, {
                        header: {
                            "x-auth-key": authKey
                        }
                    }).then(res => res.json())
                        .then(res => {
                            if (res.success) {
                                this.users.push({...res, ...{auth_key: authKey}})
                            } else {
                                accounts[id] = null
                                localStorage["authkeys"] = JSON.stringify(accounts)
                            }
                        })
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#bottom {
    position: fixed;
    bottom: 20px;
    left: 20px;

    a {
        color: #656565;
        text-decoration: none;
        margin-right: 10px;
    }
}

#auth-window .user-list {
    div {
        margin-bottom: 4px;
        white-space: pre;

        img {
            width: 45px;
            height: 45px;
            display: inline-block;
            vertical-align: middle;
            border-radius: 30px;
            border: #00000011 solid 2px;
        }

        h4 {
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            margin-left: 10px;
            color: #545454;
            overflow: hidden;

            max-width: calc(100% - 120px);
            text-overflow: ellipsis;

            margin-top: 0;
            margin-bottom: 0;
        }

        .button {
            float: right;
        }
    }
}
</style>